/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import "jspdf-autotable";
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { FaUser } from "react-icons/fa";

class News extends Component {
  constructor(props) {
    super(props);
    this.ticketSubmit = this.ticketSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      offset: 0,
      size: 5,
      page: 1,
      errorsticket: {},
      fieldsticket: {},
      ticketTitle: "",
      fieldsAddUser: {},
      fieldTicketId: null,
      dropdownVisible: false,
      isResolved: null,
      selectedValue: "",
      keyWord: ""
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.users.isTicketCreated) {
      console.log('nextProps.users.isTicketCreated', nextProps.users.isTicketCreated);
      return {
        ...nextProps,
        fieldsticket: {},
        errorsticket: {},
      }
    } else {
      return {
        ...nextProps
      }
    }
  }

  componentDidMount() {
    let temp = {
      "status": "approved",
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(userActions.getNewsList(temp));
    window.scrollTo(0, 0)
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldsticket = this.state.fieldsticket;
    let errorsticket = this.state.errorsticket;
    fieldsticket[name] = value;
    errorsticket[name] = "";
    this.setState({ fieldsticket, errorsticket });
  }

  ticketSubmit = async (e) => {
    e.preventDefault();
    if (this.handleValidationTicket()) {
      let { title, msg } = this.state.fieldsticket;

      let temp = {
        "keyWord": "",
        "pageNo": "1",
        "size": "5"
      }

      try {
        await this.props.dispatch(
          userActions.createTicket({
            title: title,
            msg: msg,
          }, temp, this.props)
        );


        this.setState({ isTicketSubmitted: true });
        this.setState({ fieldsticket: {} })

        setTimeout(() => {
          this.setState({ isTicketSubmitted: false });
        }, 4000);
      } catch (error) {

        console.error("Ticket submission error:", error);
      }
    }
  };



  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldsticket: {},
      errorsticket: {},
    })
    this.hideErrorcomment();
  }

  handleValidationTicket = () => {
    let fieldsticket = this.state.fieldsticket;
    let errorsticket = {};
    let formIsValid = true;
    if (!fieldsticket["title"] || !fieldsticket["title"] === "" || !fieldsticket["title"].trim() || fieldsticket["title"].trim().length === 0) {
      formIsValid = false;
      errorsticket["title"] = "Subject is required!";
    }
    // }

    //msg
    if (!fieldsticket["msg"]) {
      formIsValid = false;
      errorsticket["msg"] = "The message is required";
    }
    if (fieldsticket["msg"] && fieldsticket["msg"].length < 20) {
      formIsValid = false;
      errorsticket["msg"] = "The message must be at least 20 characters ";
    }

    this.setState({ errorsticket: errorsticket });
    return formIsValid;
  }

  onClickMenu = (url) => {
    this.props.history.push(url)
  }


  handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * this.state.size);
    this.setState({ offset: offset, page: data.selected });
    let datatemp = {
      "keyWord": this.state.keyWord,
      "pageNo": data.selected + 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getTicketList(datatemp));
  }


  validattionReply = () => {
    let fieldsticket = this.state.fieldsticket;
    let errorsticket = {};
    let formIsValid = true;

    if (!fieldsticket["msgg"] || !fieldsticket["msgg"] === "" || !fieldsticket["msgg"].trim() || fieldsticket["msgg"].trim().length === 0) {
      formIsValid = false;
      errorsticket["msgg"] = "The message is required";
    }

    this.setState({ errorsticket: errorsticket });
    return formIsValid;
  }

  handleRes = () => {
    if (this.validattionReply()) {
      let datatemp = {
        ticketId: this.state.fieldTicketId,
        msg: this.state.fieldsticket.msgg
      }

      let temp = {
        "keyWord": "",
        "pageNo": this.state.page,
        "size": "5"
      }

      this.props.dispatch(userActions.replyMsgTicket(datatemp, temp))
    }
  }

  TicketListById = (data) => {
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": "5"
    }

    this.setState({ fieldTicketId: data.id })
    let resDataLast = {
      ticketId: data.id,
    }
    this.props.dispatch(userActions.MsgListTicketById(resDataLast, temp));
  }


  onClose = () => {
    this.setState({ fieldTicketId: null })
  }
  updateTicketStatus = (data) => {
    this.setState({ isResolved: data.isResolve })
    this.setState({ ticketTitle: data?.title || "" })
    let temp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": "5"
    }


    this.setState({ fieldTicketId: data.id })

    let resDataLast = {
      ticketId: data.id,
    }
    this.props.dispatch(userActions.MsgListTicketById(resDataLast, temp))
  }


  changeSelectedValue = (e) => {
    this.setState({ selectedValue: e.target.value })
  }
  handlemore = (e) => {
    this.props.history.push(`/news/${e?._id}`)
  }

  handelKeyWord = (e) => {
    this.setState({ keyWord: e.target.value })
    let temp = {
      "status": "approved",
      "keyWord": e.target.value,
      "pageNo": 1,
      "size": 100
    }
    this.props.dispatch(userActions.getNewsList(temp));
  }

  render() {

    let { users } = this.props;
    let { loading, newsData } = users;

    return (
      <>
        <Helmet>
          <title>VuneCoin - news</title>
          <meta property="og:image" content="/img/logo.png" />
        </Helmet>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text='Please wait...' />
        </div>



        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
          <section className="w-11/12 mx-auto pb-20">
            <div className='flex justify-between items-center py-10 '>
              <div className="">
                <h1 className="text-xl sm:text-2xl lg:text-[30px] font-semibold text-white">
                  VuneCoin News
                </h1>
              </div>

              <div className="relative">
                <input
                  type="text"
                  className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                  placeholder="Search news..."
                  value={this.state.keyWord}
                  onChange={this.handelKeyWord}
                />
                <div className="absolute top-4 right-3">
                  <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500" />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1  xl:grid-cols-2 gap-4">
              {newsData && newsData.length > 0
                ? newsData.map((elements, index) => (
                  <div className="px-3 mb-6 w-full" key={index}>
                    <div className="flex flex-col sm:flex-row w-full h-full bg-white overflow-hidden rounded">
                      <div className="w-full sm:w-2/6">
                        <img
                          className="object-cover h-full w-full"
                          alt="Blog Image"
                          src={elements?.image || "/img/blockchain-img-demo.webp"}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/img/blockchain-img-demo.webp";
                          }}
                        />
                      </div>
                      <div className="w-full sm:w-4/6 p-5">
                        <div className="space-y-2">
                          <h2
                            className="text-black leading-normal text-lg hover:underline cursor-pointer"
                            onClick={() => this.handlemore(elements)}
                            dangerouslySetInnerHTML={{ __html: elements.title }}
                          ></h2>
                          <p
                            className="text-sm text-black line-clamp-3"
                            dangerouslySetInnerHTML={{ __html: elements.story }}
                          ></p>
                        </div>
                        <div className="flex flex-wrap justify-between items-center mt-6">
                          <div className="inline-flex items-center">
                            <div className="w-5 h-5 rounded-full overflow-hidden flex justify-center items-center flex-shrink-0 bg-black">
                              <FaUser color="#fff" size={10} />
                            </div>
                            <div className="flex-1 pl-2">
                              <h2 className="text-black">
                                {elements?.author ? elements.author : "-"}
                              </h2>
                            </div>
                          </div>
                          <div>
                            <p className="text-black opacity-50 text-xs">
                              {moment(
                                new Date(
                                  parseInt(elements?.createdAt || "-")
                                )
                              )
                                .utcOffset("+05:30")
                                .format("DD-MM-YYYY HH:mm")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </section>
        </div>



      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}
export default connect(mapStateToProps)(News);
